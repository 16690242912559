import { createElement, useEffect, useMemo, useRef, useState } from 'react';
import Script from 'next/script';

export const useExternalScripts = (scripts: string[] = []) => {
  const ref = useRef<string[]>([]);
  const [loadPaths, setLoadPaths] = useState<string[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(scripts.length === 0);

  const loadScripts = (scriptPaths: string[]) => {
    const paths = scriptPaths.filter(
      (script) => !document.getElementById(script),
    );

    if (!paths.length) {
      setIsLoaded(true);
      return;
    }

    setLoadPaths(paths);
  };

  useEffect(() => {
    if (scripts?.length && !isLoaded) {
      loadScripts(scripts);
    }
  }, [scripts, isLoaded]);

  const components = useMemo(
    () =>
      loadPaths.map((script) =>
        createElement(Script, {
          src: script,
          key: script,
          id: script,
          onLoad: () => {
            ref.current = [...ref.current, script];
            if (ref.current.length === loadPaths.length) {
              setIsLoaded(true);
            }
          },
        }),
      ),
    [loadPaths],
  );

  return {
    components,
    isLoaded,
    loadScripts,
  };
};
