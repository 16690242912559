import { FC, useEffect, useMemo } from 'react';
import {
  MapProviderAdapterConstructor,
  MapProviderProps,
} from '../types/provider';
import { useExternalScripts } from '../../../hooks/use-external-scripts';
import { MapSpinner } from '../components/map-spinner/map-spinner';
import { MapContext } from '../context/map-context';
import { PoiService } from '../services/poi-service';

export interface CreateProviderProps {
  adapter: MapProviderAdapterConstructor;
  scripts?: string[];
}

const DefaultLoader = <MapSpinner />;

export const createMapProvider = ({
  adapter,
  scripts = [],
}: CreateProviderProps): FC<MapProviderProps> => {
  const poiService = new PoiService();

  return function MapProvider({ options, loader = DefaultLoader, children }) {
    const provider = useMemo(() => adapter(options, poiService), []);
    const { components, isLoaded } = useExternalScripts(scripts);

    return (
      <>
        <MapContext.Provider value={provider}>
          {isLoaded ? children : loader}
        </MapContext.Provider>
        {components}
      </>
    );
  };
};
