const { LRUCache } = require('lru-cache');
const enableCache = process.env.NODE_ENV === 'production';

const CACHE_MAX_SIZE = 5000;
const CACHE_MAX_AGE_IN_S = enableCache ? 60 * 5 : 1;
const CACHE_ERROR_MAX_AGE_IN_S = enableCache ? 60 : 1;

global.cache =
  global.cache ??
  new LRUCache({
    max: CACHE_MAX_SIZE,
    ttl: CACHE_MAX_AGE_IN_S * 1000,
  });

const getCacheKey = (method = '', url = '', params = {}) => {
  return `${method}${url}${JSON.stringify(params)}`;
};

const onRequest = (request) => {
  const { method, url } = request;
  const params = request.params;

  if (params?.preview || method !== 'get') {
    return request;
  }

  const cacheKey = getCacheKey(method, url, params);
  const cachedResponse = cache.get(cacheKey);
  if (cachedResponse) {
    request.adapter = (config) => {
      return Promise.resolve({
        __cached: true,
        data: cachedResponse,
        status: 200,
        statusText: 'OK',
        headers: request.headers,
        config,
        request,
      });
    };
  }
  return request;
};

const onResponse = (response) => {
  if (!response.__cached) {
    const { method, url } = response.config;
    const params = response.config.params;
    const cacheKey = getCacheKey(method, url, params);
    cache.set(cacheKey, response.data);
  }
  return response;
};

const exportable = {
  CACHE_MAX_SIZE,
  CACHE_MAX_AGE_IN_S,
  CACHE_ERROR_MAX_AGE_IN_S,
  onRequest,
  onResponse,
};

module.exports = {
  ...exportable,
  default: exportable,
};
