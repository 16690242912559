import { Poi as RawPoi } from '../../../../types/content-schema';
import { Poi } from '../../types/poi';
import { CURRENT_LOCATION_ID } from './adapter';
import { VisioGlobePlace } from './types';

export const createNormalizePoi = async (
  map: typeof window.VisioWebEssential,
) => {
  const ids = map.content.queryAllPlaceIDs();
  const places = ids.map((id: string) => ({ id }));
  const ssuMarker = await map.content.getPlace({ id: CURRENT_LOCATION_ID });

  return async (poi: RawPoi): Promise<Poi | undefined> => {
    const place = places.find((p: VisioGlobePlace) => p.id === poi.id);

    if (!place) {
      return undefined;
    }

    try {
      const contentPlace = map.content.getPlace({ id: place.id });
      const { data } = await map._mapviewer.computeRoute({
        src: {
          floor: ssuMarker?.floor,
          lat: ssuMarker?.position.lat,
          lon: ssuMarker?.position.lon,
        },
        dst: place.id,
      });

      place.position = {
        ...place.position,
        ...contentPlace.position,
        floor: contentPlace?.floor,
        distanceInMeters: data.length === 0 ? undefined : data.length,
        distanceInSeconds: !data.error ? Math.round(data.duration) : undefined,
      };
    } catch (e) {}

    return {
      id: place.id,
      name: poi.name,
      defaultName: poi.name,
      description: poi.description,
      categories: poi.categories || [],
      images: poi.images,
      tags: poi.tags,
      bubble: poi.location?.bubble,
      floor: place.position.floor,
      icon: poi.icon,
      position: place.position,
      openingTimes: poi.openingTimes,
    };
  };
};
