import { MapCurrentPosition } from '../../../types/provider';
import { VisioGlobePoi } from '../types';

const MIN_MAP_RADIUS = 200;
const MAX_MAP_RADIUS = 1500;

interface CreateMapInstanceProps {
  element: HTMLDivElement;
  currentLocation: MapCurrentPosition;
  baseUrl?: string;
  hash?: string;
}

export const createMapInstance = async ({
  element,
  currentLocation,
  baseUrl,
  hash,
}: CreateMapInstanceProps) => {
  const map = new window.VisioWebEssential({
    element: element,
    imagePath: '/maps/visioglobe/img',
  });

  map.setParameters({
    parameters: {
      baseUrl,
      hash,
      location: {
        lat: currentLocation.latitude,
        lon: currentLocation.longitude,
      },
      floor: currentLocation.floor,
    },
  });

  await map.createMapviewer();

  map._mapviewer.camera.rotation = currentLocation.rotation || 0;
  map._mapviewer.camera.minRadius = MIN_MAP_RADIUS;
  map._mapviewer.camera.maxRadius = MAX_MAP_RADIUS;
  map._mapviewer.camera.rotationInertiaEnabled = false;
  map._mapviewer.camera.rotationManipulatorEnabled = false;
  map._mapviewer.camera.pitchInertiaEnabled = false;
  map._mapviewer.camera.pitchManipulatorEnabled = false;

  const mapViewerPoisObject = map._mapviewer.getPOIs();
  const mapViewerPoisArray =
    Object.values<VisioGlobePoi[]>(mapViewerPoisObject);

  // make sure that all the icons are not clickable
  mapViewerPoisArray.forEach((poi) => {
    poi[0]?.options('clickable', false);
  });

  return map;
};
