import { DEFAULT_LOCALE } from '../../../../constants/i18n';
import { ComponentId } from '../../enums/components';
import { Event } from '../../enums/events';
import type { Poi } from '../../types/poi';
import type {
  MapGetPoisProps,
  MapProviderAdapter,
  MapProviderAdapterConstructor,
  MapSetBoundsOptions,
} from '../../types/provider';

import { createMapInstance } from './helpers/init';
import { createNormalizePoi } from './normalize';
import type { VisioGlobePlace } from './types';

let map: typeof window.VisioWebEssential | null = null;
let youAreHerePoi: VisioGlobePlace;
let youAreHerePoiParameters: VisioGlobePlace;
let youAreHereMarkerElement: HTMLElement;
let poiMarkerElement: HTMLElement;
let mapElement: HTMLElement;

export const CURRENT_LOCATION_ID = `${ComponentId.YOU_ARE_HERE_MARKER}-visioglobe`;
const POI_LOCATION_ID = `${ComponentId.LOCATION_MARKER}-visioglobe`;
const CUSTOM_MARKER_PREFIX = 'marker-';

const DEFAULT_VIEWPORT_PADDING = {
  top: 500,
  right: 100,
  bottom: 500,
  left: 100,
};

const DEFAULT_VIEWPORT_RADIUS = 200;

const CAMERA_ANIMATION_DURATION = 0.5;

export const VisioGlobeAdapter: MapProviderAdapterConstructor = (
  options,
  poiService,
) => {
  const { baseUrl, hash } = options;
  let mapLocale = DEFAULT_LOCALE;

  function isInitialized() {
    return !!map;
  }

  const init: MapProviderAdapter['init'] = async ({
    element,
    currentLocation,
    locale,
  }) => {
    mapLocale = locale;
    mapElement = element;

    youAreHereMarkerElement = document.createElement('div');
    youAreHereMarkerElement.id = CURRENT_LOCATION_ID;
    youAreHereMarkerElement.style.visibility = 'hidden';

    poiMarkerElement = document
      .getElementById(ComponentId.LOCATION_MARKER)
      ?.cloneNode(true) as HTMLElement;
    poiMarkerElement.id = POI_LOCATION_ID;
    poiMarkerElement.style.visibility = 'hidden';

    element.parentElement?.appendChild(youAreHereMarkerElement);
    element.parentElement?.appendChild(poiMarkerElement);

    map = await createMapInstance({ element, currentLocation, baseUrl, hash });
    map._mapviewer.on(Event.MOUSE_UP, checkIfClickIsPoi);

    if (currentLocation.floor) {
      await map?.venue.goToFloor({ id: currentLocation.floor });
    }

    // Create a "You are here" marker
    youAreHerePoiParameters = {
      id: CURRENT_LOCATION_ID,
      selector: `#${CURRENT_LOCATION_ID}`,
      position: {
        lat: currentLocation.latitude,
        lon: currentLocation.longitude,
        distanceInMeters: 0,
        distanceInSeconds: 0,
      },
      floor: currentLocation.floor,
      overlay: true,
    };

    await createCurrentPositionPoi(youAreHerePoiParameters);
    await updatePois();

    // Make markers visible after loading everything
    poiMarkerElement.style.visibility = 'visible';
    youAreHereMarkerElement.style.visibility = 'visible';
  };

  async function setMarkerToCurrentFloor(floorId: string) {
    try {
      await map.content?.removePlace({ id: CURRENT_LOCATION_ID });
    } catch (e) {
      console.warn(e);
    }

    youAreHerePoiParameters = {
      ...youAreHerePoiParameters,
      floor: floorId,
    };

    await createCurrentPositionPoi(youAreHerePoiParameters);
  }

  async function createCurrentPositionPoi(
    youAreHerePoiParameters: VisioGlobePlace,
  ) {
    youAreHerePoi = await map.content?.createPlace({
      parameters: { ...youAreHerePoiParameters },
    });
  }

  function checkIfClickIsPoi(event: any) {
    // only if the poi has content then it should be highlighted
    const id = event?.args?.element?.vg?.id;
    if (!poiService.pois.map((poi) => poi.id).includes(id)) {
      return false;
    }
  }

  const addEventListener: MapProviderAdapter['addEventListener'] = (
    event,
    cb,
  ) => {
    map?._mapviewer?.on(event, cb);
  };

  const removeEventListener: MapProviderAdapter['removeEventListener'] = (
    event,
    cb,
  ) => {
    map?._mapviewer?.off(event, cb);
  };

  async function updatePois() {
    const normalizePoi = await createNormalizePoi(map);
    poiService.updateNormalizer(normalizePoi);
    await poiService.fetchAll({ locale: mapLocale });
  }

  const search: MapProviderAdapter['search'] = async (searchQuery, options) => {
    return poiService.search(searchQuery, options);
  };

  const fetchPoiById: MapProviderAdapter['fetchPoiById'] = (id) => {
    return poiService.fetchById(id);
  };

  const getPoiById: MapProviderAdapter['getPoiById'] = async (id) => {
    return poiService.getById(id);
  };

  async function setBounds(pois: Poi[], options: MapSetBoundsOptions = {}) {
    const { padding: customPadding } = options;
    const poiPoints = pois.map((poi) => poi.position);

    const padding = {
      ...DEFAULT_VIEWPORT_PADDING,
      ...customPadding,
    };

    const viewPoint = map.venue.getViewpoint({
      points: [...poiPoints],
      padding,
    });

    await map.venue.goToViewpoint({
      ...viewPoint,
      animationDuration: CAMERA_ANIMATION_DURATION,
    });
  }

  const goToPoi: MapProviderAdapter['goToPoi'] = async (id, options = {}) => {
    if (!map) {
      return;
    }
    try {
      const { withoutMarker } = options;
      const poi = await map.content.getPlace({ id });
      await goToFloor(poi.floor);
      await map.content.setActivePlace({ place: id });
      map._mapviewer.resetPlaceColor([id]);

      // Todo: determine if we want to separate the marker placement logic from camera movement logic. Could be fixed with [MS-852]
      if (!withoutMarker) {
        await removeSelectedLocationPoi();
        addSelectedLocationPoi(poi.position);
      }

      setBounds([poi], options);
      return poi;
    } catch (e) {
      console.log(e);
    }
  };

  async function addSelectedLocationPoi(position: {
    lat: string;
    lon: string;
  }) {
    const selectedPoi = await getSelectedPoi();

    if (selectedPoi) {
      return map.content.setPlacePosition({ id: POI_LOCATION_ID, position });
    }

    return map.content.createPlace({
      parameters: {
        id: POI_LOCATION_ID,
        selector: `#${POI_LOCATION_ID}`,
        position,
        overlay: true,
      },
    });
  }

  const startRoute: MapProviderAdapter['startRoute'] = async (id) => {
    const from = {
      lat: map.parameters.location.lat,
      lon: map.parameters.location.lon,
      floor: map.parameters.floor,
    };

    await map.route.setFrom({
      from,
    });

    map?._mapviewer?.trigger(Event.START_ROUTE, { from, to: id });

    await map.route.setTo({ to: id });
    return goToSsuLocation();
  };

  const stopRoute: MapProviderAdapter['stopRoute'] = async () => {
    map?._mapviewer?.trigger(Event.STOP_ROUTE);
    return map.route.clear();
  };

  async function removeSelectedLocationPoi() {
    const poi = await getSelectedPoi();

    if (!poi) {
      return undefined;
    }

    return map.content.removePlace({ id: poi.id });
  }

  async function getSelectedPoi() {
    try {
      return map.content.getPlace({ id: POI_LOCATION_ID });
    } catch (e) {
      return undefined;
    }
  }

  const resetView: MapProviderAdapter['resetView'] = async (options = {}) => {
    if (!map?.venue) {
      return;
    }

    const { shouldGoToSsuLocation } = options;

    map?.content?.resetActivePlace();
    await removeSelectedLocationPoi();

    if (shouldGoToSsuLocation) {
      await goToSsuLocation();
    }
  };

  async function goToSsuLocation() {
    const currentLocationPoints = await map.venue.getFootprintPoints({
      id: CURRENT_LOCATION_ID,
    });
    const viewPoint = map.venue.getViewpoint({
      points: currentLocationPoints,
      padding: DEFAULT_VIEWPORT_PADDING,
    });

    viewPoint.radius = DEFAULT_VIEWPORT_RADIUS;
    await map.venue.goToViewpoint(viewPoint);
  }

  const terminate: MapProviderAdapter['terminate'] = async () => {
    try {
      if (!map) {
        return;
      }
      map._mapviewer.off(Event.MOUSE_UP, checkIfClickIsPoi);
      await map.destroyMapviewer();
    } catch (e) {
      console.warn(e);
    }
  };

  const goToFloor: MapProviderAdapter['goToFloor'] = async (id) => {
    if (id === map?.venue.currentFloorID) {
      return;
    }
    setMarkerToCurrentFloor(id);
    await map?.venue.goToFloor({ id });
  };

  function getCurrentFloorId() {
    return map?.venue?.currentFloorID;
  }

  function addMarkersGroup(pois: Poi[], element: HTMLDivElement) {
    const elements: HTMLDivElement[] = [];

    pois.forEach((poi) => {
      const cloned = element.cloneNode(true) as HTMLDivElement;
      cloned.id = poi.id;
      elements.push(cloned);
      mapElement.appendChild(cloned);
      cloned.style.visibility = 'visible';
      void addMarker(poi);
    });

    return function removeCategoryMarkers() {
      elements.forEach((el) => {
        removeMarker(el.id);
        el.remove();
      });
    };
  }

  const addMarker: MapProviderAdapter['addMarker'] = async (poi) => {
    const parameters = {
      id: `${CUSTOM_MARKER_PREFIX}${poi.id}`,
      selector: `#${poi.id}`,
      position: {
        lat: poi.position.lat,
        lon: poi.position.lon,
      },
      floor: poi.floor,
      overlay: true,
    };

    return map?.content?.createPlace({ parameters });
  };

  const removeMarker: MapProviderAdapter['removeMarker'] = async (id) => {
    try {
      return map?.content?.removePlace({ id: `${CUSTOM_MARKER_PREFIX}${id}` });
    } catch (e) {
      console.warn(e);
    }
  };

  const getPoiIdFromMapMouseEvent: MapProviderAdapter['getPoiIdFromMapMouseEvent'] =
    (event) => {
      return event?.args?.element?.vg?.id;
    };

  const updateYouAreHereMarkerElement: MapProviderAdapter['updateYouAreHereMarkerElement'] =
    async (element) => {
      const youAreHereMarkerElement = document.querySelector(
        `#${CURRENT_LOCATION_ID}`,
      );
      if (youAreHereMarkerElement) {
        youAreHereMarkerElement.innerHTML = element.innerHTML || '';
      }
    };

  const updateYouAreHereMarkerLocation: MapProviderAdapter['updateYouAreHereMarkerLocation'] =
    async (position) => {
      try {
        const { latitude, longitude, heading } = position;
        await map?.content?.setPlacePosition({
          id: CURRENT_LOCATION_ID,
          position: { lat: latitude, lon: longitude },
        });
        await map.venue.goToViewpoint({
          position: { lat: latitude, lon: longitude, heading },
          animationDuration: CAMERA_ANIMATION_DURATION,
        });
      } catch (e) {
        console.warn(e);
      }
      return;
    };

  const centerMapToYouAreHereMarker: MapProviderAdapter['centerMapToYouAreHereMarker'] =
    async () => {
      try {
        await goToSsuLocation();
      } catch (e) {
        console.warn(e);
      }
      return;
    };

  const getPois: MapProviderAdapter['getPois'] = ({
    locale,
  }: MapGetPoisProps) => {
    return poiService.fetchAll({ locale });
  };

  return {
    init,
    isInitialized,
    goToPoi,
    search,
    fetchPoiById,
    getPoiById,
    getPois,
    resetView,
    terminate,
    addEventListener,
    removeEventListener,
    updatePois,
    startRoute,
    stopRoute,
    goToFloor,
    getCurrentFloorId,
    addMarker,
    removeMarker,
    addMarkersGroup,
    getPoiIdFromMapMouseEvent,
    updateYouAreHereMarkerElement,
    // No need to remove the marker. With VisioGlobe its fine to just update the innerHTML
    removeYouAreHereMarker: () => Promise.resolve(),
    updateYouAreHereMarkerLocation,
    centerMapToYouAreHereMarker,
  };
};
