import { Poi } from './poi';

export type PoiSearchResponse = Poi[];

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface PoiSearchQuery {
  query: string;
  categories: string[];
  bubbleId?: string;
  defaultCategories: string[];
}

export interface PoiSearchSortOptions {
  name: SortOrder;
  distance: SortOrder;
}

export interface PoiSearchOptions {
  limit: number;
  sort?: Partial<PoiSearchSortOptions>;
  locale?: string;
}
