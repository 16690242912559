import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { LRUCache } from 'lru-cache';
import qs from 'qs';
import { CACHE_MAX_AGE_IN_S, CACHE_MAX_SIZE } from 'pxp-api/cache';

import { ApiRoute } from '../../../enums/route';
import { customFetch } from '../../../lib/custom-fetch';
import type { Poi as RawPoi } from '../../../types/content-schema';
import type { Normalizer } from '../../../types/module';
import type { Poi } from '../types/poi';
import type {
  PoiSearchOptions,
  PoiSearchQuery,
  PoiSearchSortOptions,
} from '../types/service';
import { SortOrder } from '../types/service';

const defaultNormalizer: Normalizer<any, Poi> = (input) => input;

const getCacheKey = (locale: string, url: string) => `${locale}:${url}`;

export class PoiService {
  private normalizer: Normalizer<RawPoi, Poi | undefined>;
  pois: Poi[] = [];
  cache: LRUCache<any, any>; // eslint-disable-line

  constructor(normalizer: Normalizer<RawPoi, Poi> = defaultNormalizer) {
    this.normalizer = normalizer;
    this.cache = new LRUCache({
      max: CACHE_MAX_SIZE,
      ttl: CACHE_MAX_AGE_IN_S * 1000,
    });
  }

  async updatePois(pois: RawPoi[]) {
    this.pois = await this.normalize(pois);
  }

  updateNormalizer(normalizer: Normalizer<RawPoi, Poi | undefined>) {
    this.normalizer = normalizer;
  }

  async fetchAll({ locale }: { locale: string }) {
    try {
      const queryString = qs.stringify({
        locale,
      });
      const url = `${ApiRoute.POIS}?${queryString}`;
      const cacheKey = getCacheKey(locale, url);

      if (this.cache.has(cacheKey)) {
        this.pois = this.cache.get(cacheKey) as Poi[];
        return this.pois;
      }

      const pois = await customFetch<RawPoi[]>(url);

      await this.updatePois(pois);

      this.cache.set(cacheKey, this.pois);

      return this.pois;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  search(
    { query, categories, bubbleId, defaultCategories }: PoiSearchQuery,
    { limit, sort }: PoiSearchOptions,
  ) {
    const isMatchWithTag = (poi: Poi, query: string): boolean =>
      !!poi.tags?.some((tag) => tag?.name?.toLowerCase().includes(query));
    const isMatchWithName = (poi: Poi, query: string): boolean =>
      poi.name.toLowerCase().includes(query);
    const isMatchWithBubble = (
      poi: Poi,
      _bubbleId: string | undefined,
    ): boolean =>
      _bubbleId
        ? poi.areaGroups?.some((areaGroup) => areaGroup?.id === _bubbleId) ??
          false
        : true;

    const isMatchWithCategory = (poi: Poi, _categories: string[]) =>
      _categories.length
        ? _categories.some((category) => poi.categories.includes(category))
        : true;
    const isEmpty = Boolean(!query && !categories.length);

    const sanitizedQuery = query.toLowerCase().trim();

    const filteredPois = this.pois?.filter((poi) => {
      const matchWithNameOrTag =
        isMatchWithName(poi, sanitizedQuery) ||
        isMatchWithTag(poi, sanitizedQuery);
      const matchWithBubble = isMatchWithBubble(poi, bubbleId);
      const matchWithCategory = isMatchWithCategory(poi, categories);
      const matchWithDefaultCategory = isMatchWithCategory(
        poi,
        defaultCategories,
      );

      return (
        matchWithNameOrTag &&
        matchWithBubble &&
        (isEmpty ? matchWithDefaultCategory : matchWithCategory)
      );
    });

    return this.handleSorting(filteredPois, sort).slice(0, limit);
  }

  fetchById(id: string) {
    return customFetch<Poi>(`${ApiRoute.POIS}/${id}?locale=nl`);
  }

  getById(id: string) {
    return this.pois.find((poi) => poi.id === id);
  }

  async normalize(pois: RawPoi[]): Promise<Poi[]> {
    const normalized = await Promise.all(
      uniqBy(pois, (poi) => poi.id).map(this.normalizer),
    );
    return normalized.filter(Boolean) as Poi[];
  }

  private handleSorting(pois: Poi[], sort?: Partial<PoiSearchSortOptions>) {
    const sortOptions: PoiSearchSortOptions = {
      name: SortOrder.ASC,
      distance: SortOrder.ASC,
      ...sort,
    };

    const sortMap: Record<string, string> = {
      name: 'name',
      distance: 'position.distanceInSeconds',
    };

    const { fields, order } = Object.entries(sortOptions).reduce(
      (acc, [key, order]) => {
        return {
          ...acc,
          fields: [...acc.fields, sortMap[key]],
          order: [...acc.order, order.toLowerCase()],
        };
      },
      { fields: [], order: [] } as {
        fields: string[];
        order: ('asc' | 'desc')[];
      },
    );

    return orderBy(pois, fields, order);
  }
}
