import { createMapProvider } from '../create-provider';
import { VisioGlobeAdapter } from './adapter';

const scripts = [
  '/maps/visioglobe/visioweb.js',
  '/maps/visioglobe/visioweb.essential.js',
];

const VisioGlobeProvider = createMapProvider({
  scripts,
  adapter: VisioGlobeAdapter,
});

export default VisioGlobeProvider;
